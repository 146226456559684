import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`In some cases, a user may want to revoke access given to an application. Programmatic revocation is important in instances where a user logs out, changes identity, unsubscribes, or uninstalls the application.`}</p>
    <Title mdxType="Title">Token revocation endpoint</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://video.ibm.com/oauth2/token/revoke"
            }}>{`https://video.ibm.com/oauth2/token/revoke`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported HTTP method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`POST`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The token that the client wants to get revoked. Can be access token or refresh token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-character long string that identifies the client the token belongs to.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In addition to the parameters above, the client must provide its client secret (provided along with the client key) to authenticate itself. The authentication is done with HTTP Basic authorization method.`}</p>
    <p>{`Sample HTTP header:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Basic bc345abc45d6789abcdef0123aef0126789def01
`}</code></pre>
    <p>{`For example, a client may request the revocation of a token with the following request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /oauth2/token/revoke HTTP/1.1
Host: video.ibm.com
Content-Type: application/x-www-form-urlencoded
Authorization: Basic bc345abc45d6789abcdef0123aef0126789def01

token=5fb2a4aba6157825929b78c56e623613f20033ff&client_id=50aefe42c68cf63b488fc6630b7f0b3b7ae00e8e
`}</code></pre>
    <h3>{`Revocation Response`}</h3>
    <p>{`The authorization server responds with HTTP status code 200 if the token has been revoked successfully or if the client submitted an invalid token. The content of the response body can be ignored by the client as all necessary information is conveyed in the response code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      